<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<common-header :formModel="formInline" @query="query" class="common_header">
			>
			<template v-slot:form>
				<el-form-item :label="$t('banner.title')" prop="param.title">
					<el-input v-model="formInline.param.title"></el-input>
				</el-form-item>
			</template>
			<template v-slot:tools>
				<tools-btn left-icon="add" @click.native="add()">{{ $t('common.add') }}</tools-btn>
			</template>
		</common-header>
		<div class="table tableHeight">
			<el-table border stripe size="mini" height="100%" :data="tableData">
				<el-table-column show-overflow-tooltip prop="title" :label="$t('banner.title')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="bannerLocation" :label="$t('banner.bannerLocation')">
					<template v-slot="{ row }">
						{{ bannerLocationObj.find(e => e.value == row.bannerLocation).label }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="bannerType" :label="$t('banner.bannerType')">
					<template v-slot="{ row }">
						{{ bannerTypeObj.find(e => e.value == row.bannerType).label }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="bannerUrl" :label="$t('banner.bannerUrl')">
					<template slot-scope="scope">
						<el-image
							v-if="scope.row.bannerUrlShow"
							class="rowImg"
							:src="scope.row.bannerUrlShow"
							:preview-src-list="[scope.row.bannerUrlShow]"
						></el-image>
					</template>
				</el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="pageParam"
					:label="$t('banner.pageParam')"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="pageUrl" :label="$t('banner.pageUrl')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="isUp" :label="$t('common.isUp')">
					<template v-slot="{ row }">
						{{ row.isUp ? $t('common.up') : $t('common.down') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="beginUseTime" :label="$t('banner.beginUseTime')">
					<template v-slot="{ row }">
						{{ row.beginUseTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="endUseTime" :label="$t('banner.endUseTime')">
					<template v-slot="{ row }">
						{{ row.endUseTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
					</template>
				</el-table-column>

				<el-table-column :label="$t('common.operation')" width="140">
					<template v-slot="{ row }">
						<el-button size="mini" type="text" @click="edit(row)">
							{{ $t('common.edit') }}
						</el-button>
						<el-button size="mini" type="text" @click="isUp(row)">
							{{ row.isUp ? $t('common.down') : $t('common.up') }}
						</el-button>
						<el-button size="mini" type="text" @click="del(row)">
							{{ $t('common.del') }}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<gl-page
			@size-change="sizeChange"
			@current-change="query"
			:current-page="formInline.pageNum"
			:page-size="formInline.pageSize"
			:total="total"
		></gl-page>
		<el-dialog :title="title" :visible.sync="addVisible" width="730px" center :close-on-click-modal="false">
			<el-form ref="addForm" :model="addForm" label-width="120px" class="form" :rules="rules">
				<el-form-item :label="$t('banner.title')" prop="title">
					<el-input size="mini" v-model="addForm.title"></el-input>
				</el-form-item>
				<el-form-item :label="$t('banner.bannerLocation')" prop="bannerLocation">
					<el-select size="mini" v-model="addForm.bannerLocation" placeholder="请选择轮播图位置">
						<el-option
							v-for="item in bannerLocationObj"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('banner.bannerType')" prop="bannerType">
					<el-select size="mini" v-model="addForm.bannerType" placeholder="请选择轮播类型">
						<el-option
							v-for="item in bannerTypeObj"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('banner.pageParam')" prop="pageParam">
					<el-input size="mini" v-model="addForm.pageParam"></el-input>
				</el-form-item>
				<el-form-item :label="$t('banner.beginUseTime')" prop="beginUseTime">
					<el-date-picker
						v-model="addForm.beginUseTime"
						type="datetime"
						placeholder="选择开始时间"
						size="mini"
					></el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('banner.endUseTime')" prop="endUseTime">
					<el-date-picker
						v-model="addForm.endUseTime"
						type="datetime"
						placeholder="选择结束时间"
						size="mini"
					></el-date-picker>
				</el-form-item>

				<el-form-item :label="$t('banner.bannerUrl')" prop="bannerUrl">
					<!-- <el-input size="mini" v-model="addForm.bannerUrl"></el-input> -->
					<el-upload
						class="upload_wrap"
						:auto-upload="false"
						drag
						action="#"
						multiple
						:on-change="
							e => {
								return fileListChange(e, 'bannerUrl')
							}
						"
						:show-file-list="false"
					>
						<i v-if="!addForm.bannerUrl" class="el-icon-upload"></i>
						<div v-if="!addForm.bannerUrl" class="el-upload__text">
							{{ $t('batchImport.descF') }}
							<em>{{ $t('batchImport.descS') }}</em>
						</div>
						<el-image
							class="editImg"
							v-if="addForm.bannerUrl && editImg == ''"
							:src="addForm.bannerUrlShow"
						></el-image>
						<div class="editImg" v-else ref="editImg"></div>
					</el-upload>
				</el-form-item>
				<el-form-item :label="$t('banner.pageUrl')" prop="pageUrl">
					<el-input size="mini" v-model="addForm.pageUrl"></el-input>
					<!-- <el-upload
						class="upload_wrap"
						:auto-upload="false"
						drag
						action="#"
						multiple
						:on-change="
							e => {
								return fileListChange(e, 'pageUrl')
							}
						"
						:show-file-list="false"
					>
						<i v-if="!addForm.pageUrl" class="el-icon-upload"></i>
						<div v-if="!addForm.pageUrl" class="el-upload__text">
							{{ $t('batchImport.descF') }}
							<em>{{ $t('batchImport.descS') }}</em>
						</div>
						<el-image class="editImg" v-if="addForm.pageUrl" :src="addForm.pageUrl"></el-image>
					</el-upload> -->
				</el-form-item>
				<el-form-item :label="$t('banner.isUp')" prop="isUp">
					<el-switch v-model="addForm.isUp"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			total: 0,
			tableData: [],
			formInline: {
				pageNum: 1,
				pageSize: 10,
				param: {
					bannerType: '',
					isUp: '',
					title: ''
				}
			},
			addForm: {
				bannerLocation: 'index',
				bannerType: '',
				bannerUrl: '',
				beginUseTime: '',
				endUseTime: '',
				id: '',
				isUp: true,
				pageParam: '',
				pageUrl: '',
				sort: '',
				title: ''
			},
			addVisible: false,
			rules: {
				title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
				bannerLocation: [{ required: true, message: '请选择轮播位置', trigger: 'blur' }],
				bannerType: [{ required: true, message: '请选择轮播类型', trigger: 'blur' }],
				bannerUrl: [{ required: true, message: '请输入轮播地址', trigger: 'blur' }],
				beginUseTime: [{ required: true, message: '请选择有效开始时间', trigger: 'blur' }],
				endUseTime: [{ required: true, message: '请选择有效结束时间', trigger: 'blur' }],
				isUp: [{ required: true, message: '请选择是否上下架', trigger: 'blur' }],
				pageUrl: [{ required: true, message: '请输入页面地址', trigger: 'blur' }],
				pageParam: [{ required: true, message: '请输入页面参数', trigger: 'blur' }]
			},
			title: '',
			bannerTypeObj: [
				{ value: 'img', label: '图片' },
				{ value: 'video', label: '视频' }
			],
			bannerLocationObj: [{ value: 'index', label: '首页' }],
			editImg: ''
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		fileListChange(file, type) {
			this.editImg = 'add'
			// console.log(file, type)
			const form = new FormData()
			form.append('file', file.raw)
			this.ajax.post(`/api/workOrder/uploadPic`, form, { 'Content-Type': 'multipart/form-data' }).then(res => {
				console.log(res)
				this.addForm[type] = res
				let oFReader = new FileReader()
				oFReader.readAsDataURL(file.raw)
				let _this = this
				oFReader.onloadend = e => {
					let src = e.target.result
					_this.$nextTick(() => {
						let dom = _this.$refs.editImg
						dom.style.backgroundImage = `url(${src})`
					})
				}
			})
		},
		edit(row) {
			this.editImg = ''
			this.addVisible = true
			this.title = this.$t('common.edit')
			Object.assign(this.addForm, row)
		},
		del(row) {
			this.ajax.post('/api/banner/delBanner', { id: row.id }).then(() => {
				this.$message.success(this.$t('common.success'))
				this.query()
			})
		},
		isUp(row) {
			console.log(row)
			this.ajax.post('/api/banner/changeStatus', { id: row.id, isUp: !row.isUp }).then(() => {
				this.$message.success(this.$t('common.success'))
				this.query()
			})
		},
		save() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
					this.ajax.post('/api/banner/saveBanner', this.addForm).then(() => {
						this.addVisible = false
						this.query()
					})
				}
			})
		},
		add() {
			this.editImg = 'add'
			this.addVisible = true
			this.$nextTick(() => {
				let dom = this.$refs.editImg
				dom.style.backgroundImage = `none`
			})
			this.title = this.$t('common.add')
			this.addForm = {
				bannerLocation: 'index',
				bannerType: '',
				bannerUrl: '',
				beginUseTime: '',
				endUseTime: '',
				id: '',
				isUp: true,
				pageParam: '',
				pageUrl: '',
				sort: '',
				title: ''
			}
		},
		query(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.formInline.pageNum = i
			this.ajax.post('/api/banner/bannerList', this.formInline).then(res => {
				console.log(res)
				this.tableData = res.list
				this.total = res.total
			})
		},
		sizeChange(i) {
			this.formInline.pageSize = i
			this.query()
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .el-select {
	width: 218px;
}
.editImg {
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
</style>
